import { optionStore } from './stores'
import { update } from './post'

export function init() {
  const ignores = window.localStorage.getItem("Ignores")
  if (ignores) {
    ignores.split(',').forEach(publisher => optionStore.hidePublisher(Number(publisher)))
  }

  const rankingType = window.localStorage.getItem("RankingType")
  if (rankingType) {
    setRankingType(rankingType)
  }
  else {
    setRankingType(0)
  }
}

export function getIgnoresStr() {
  return [...optionStore.ignores].join(',')
}

export function getPublisherVisible(value) {
  return !optionStore.ignores.has(value)
}

export function togglePublisherVisible(publisher) {
  if (optionStore.ignores.has(publisher)) {
    console.log('option.showPublisher')
    optionStore.showPublisher(publisher)
  }
  else {
    console.log('option.hidePublisher')
    optionStore.hidePublisher(publisher)
  }
  
  window.localStorage.setItem("Ignores", getIgnoresStr())
}

export function getRankingType() {
  return optionStore.rankingType
}

export function setRankingType(value) {
  if (optionStore.rankingType == value) {
    return
  }
  
  console.log(`option.setRankingType [${optionStore.rankingType} -> ${value}]`)

  optionStore.setRankingType(value)
  window.localStorage.setItem("RankingType", value)

  update()
}

init()