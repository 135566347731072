import { observable } from 'mobx'
import moment from 'moment-timezone'

export class NoticeStore {
  @observable notices = [
    // template: [ 'title', `#### header`, moment('000000', 'YYMMDD') ],
    ['광고가 추가되었습니다', `
#### 먼저 양해의 말씀 드립니다

작게라도 운영비에 보태기 위해 중단과 하단, 최소한의 광고 두개를 삽입하였습니다.
혹시라도 부정적인 피드백이 있더라도 적극 고려하겠으니 많은 반응 부탁드립니다.

더 재밌고 편한 시간을 위해 노력하는 FUN100이 되겠습니다 :D
    `, moment('210418', 'YYMMDD')],
    ['다시 보기 기능 추가', `
#### 다시 보기 기능이 추가됩니다

나중에 다시 보고 싶은 글이 자주 나옵니다.  
여러가지 상황에서 금방 슉 찾아서 보여주고 싶어집니다.  
이럴 때 다시 보기 아이콘 <span uk-icon="bookmark"></span> 을 사용하세요!

사이드바에서 다시 보기 목록을 확인할 수 있습니다.  

##### 혹시 이렇다면 어떨까요?

다시 보기 목록은 브라우저에 캐시됩니다.  
캐시가 지워지거나 기기를 바뀌어도 (폰->다른폰, 또는 PC) 유지되기를 바라시나요?  
여러분의 의견 부탁드려요 :D
    `, moment('200721', 'YYMMDD')],
    ['100등 순위 표시 추가', `
#### 순위 표시가 추가됩니다 

랭킹 게시판에 순위가 표시되기를 바라는 분이 많네요!

설득당해서 순위를 표시해보니 골라보는 재미가 쏠쏠하네요.  
100개를 다 보면 기분이 더 좋아집니다 ^^

지금 바로 확인하세요!
    `, moment('200709', 'YYMMDD')],
    [
      '블라인드 수집 개시!',
      `
#### 이제 블라인드도 수집합니다 

블라 글도 보고 싶다는 요청이 많아서 블라도 수집합니다 ^^

블라인드 공개 채널이 크롤링 대상이며,  
블라인드는 다른 커뮤니티에 비해 조회수가 상대적으로 적은 점 감안하여 필터링 기능 활용 부탁드립니다. 

필터링은 사이드 바에서 찾아주세요!
`,
      moment('200707', 'YYMMDD'),
    ],
    [
      '루리웹 모바일 화면으로 안나올 때',
      `
#### 루리웹 모바일 화면 

루리웹은 최하단에서 '모바일 웹으로 고정'을 최초 한번 클릭하면 이후로 모바일 버전으로 볼 수 있습니다.

자동으로 기능 제공하지 못해서 쏘리해요!
`,
      moment('200706', 'YYMMDD'),
    ],
    [
      '앱 인듯 앱 아닌 앱 같은 너',
      `
#### Fun100을 앱처럼 사용할 수 있어요!

웹이면서 앱스토어를 통해 설치하는 앱처럼 사용할 수 있는 기능을 아시나요?

iOS, Android 모두 해당됩니다.  
설명보다 직접 화면을 보면 더 간단하겠죠^^

#### 10초 완성 따라하기

1. Safari 의 공유하기 
![200710-pwa-guide-01.png](/static/images/notice/200710-pwa-guide-01.png)

1. 홈 화면에 추가하기 
![200710-pwa-guide-02.png](/static/images/notice/200710-pwa-guide-02.png)

1. Fun100 추가!
![200710-pwa-guide-03.jpg](/static/images/notice/200710-pwa-guide-03.jpg)
`,
      moment('200705', 'YYMMDD'),
    ],
    [
      'Fun100.kr 런칭!',
      `
#### Fun100.kr 런칭!

세상 커뮤니티의 재미를 모아놓았습니다!  
이제 여러 커뮤니티를 직접 돌아다니는 수고는 그만!  
한눈에 기간별 랭킹으로 재미보세요 :D 

#### 수집 대상 커뮤니티 목록

- 루리웹
- 웃대
- 오유
- 보배드림
- 이토렌드
- 뽐뿌
- 더쿠
- 클리앙
- SLR클럽
- 딴지일보
- 블라인드

#### 잘 부탁드립니다

커뮤니티는 지속적으로 추가 중입니다. 
추가를 원하는 커뮤니티가 있다면 문의하기로 제안주세요! `,
      moment('200705', 'YYMMDD'),
    ]
  ]
}
