import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class SubHeaderComponent extends Component {
  render() {
    return (
      <div id="sub-header" className="uk-flex uk-flex-row uk-flex-center" >
        <ul className="uk-subnav uk-subnav-divider" >
          <li className={this.props.isNotice ? 'uk-active' : ''} ><Link to={'/notice'} >공지보기</Link></li>
          <li className={this.props.isPost ? 'uk-active' : ''} ><Link to={'/post'} >재미보기</Link></li>
          <li className={this.props.isBookmark ? 'uk-active' : ''} ><Link to={'/bookmark'} >다시보기</Link></li>
        </ul>
      </div >
    );
  }
}
