import { observable, action } from 'mobx'

export class BookmarkPostStore {
  @observable posts = []

  @action.bound
  add(post) {
    this.posts.push(post)
  }

  @action.bound
  del(index) {
    this.posts.splice(index, 1)
  }
}
