import React, { Component } from 'react'
import { SideBarComponent } from './sidebar'
import { update } from '../post'

export class HeaderComponent extends Component {
  handleHome() {
    console.log('handle home')
    update()
  }

  render(){
    return(
      <div id="header">
        <nav className="uk-navbar-container" uk-navbar="true">
          <button className="uk-navbar-left uk-navbar-toggle" uk-navbar-toggle-icon="true" uk-toggle="target: #sidebar" />
          <div className="uk-navbar-center uk-logo"
            onClick={() => this.handleHome()}
          >
            Fun iOO
          </div>
        </nav>
        <SideBarComponent />
      </div>
    );
  }
}
