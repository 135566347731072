import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { noticeStore } from '../stores'
import showdown from 'showdown'
import { SubHeaderComponent } from './sub-header'
const converter = new showdown.Converter()

@observer
export class NoticesComponent extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div id="notices">
        <SubHeaderComponent isNotice={true} />
        <ul className="uk-list uk-list-divider uk-text-middle">
          {
            0 < noticeStore.notices.length &&
            noticeStore.notices.map((notice, i) =>
              <li data-notice-id={i} className="notice" key={i}>
                <NoticeComponent noticeIndex={i} notice={notice} />
              </li>
            )
          }
        </ul>
        {
          noticeStore.notices.length <= 0 &&
          <div className="uk-text-center uk-margin">
            no notice
        </div>
        }
      </div>
    );
  }
}

@observer
export class NoticeComponent extends Component {
  render() {
    return (
      <div >
        <div
          className="uk-flex uk-flex-middle"
          uk-toggle={`target:#notice${this.props.noticeIndex}content`}
          // fit other pages
          style={{ height: '28px' }}>
          <div className="uk-link-text uk-text-truncate uk-text-normal" style={{ flexGrow: 1 }} >
            {this.props.notice[0]}
          </div>
          <div className="uk-text-light uk-text-center uk-text-middle">
            {this.props.notice[2].format('MM/DD')}
          </div>
        </div>
        <div
          hidden
          className="uk-padding-small"
          style={{ backgroundColor: 'beige' }}
          id={`notice${this.props.noticeIndex}content`}
          uk-toggle={`target:#notice${this.props.noticeIndex}content`}
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.notice[1]) }}>
        </div>
      </div>
    )
  }
}
