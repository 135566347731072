import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { noticeStore } from '../stores'
import { SubHeaderComponent } from './sub-header'
import * as bookmark from '../bookmark'
import { PublisherDisplayStr, PublisherStr } from '../../../common/define'

@observer
export class BookmarksComponent extends Component {
  constructor(props) {
    super(props)
    this.state = { cachedPosts: [...bookmark.reverse()] }
  }

  render() {
    return (
      <div id="notices">
        <SubHeaderComponent isBookmark={true} />
        <ul className="uk-list uk-list-divider uk-text-middle">
          {
            this.state.cachedPosts.map((post, i) =>
              <li data-post-id={i} className="post" key={i}>
                <BookmarkComponent post={post} postIndex={i} />
              </li>)
          }
          {
            this.state.cachedPosts.length <= 0 &&
            <div className="uk-text-center uk-margin"> no data </div>
          }
        </ul>
        {
          noticeStore.notices.length <= 0 &&
          <div className="uk-text-center uk-margin">
            no notice
        </div>
        }
      </div>
    );
  }
}

@observer
export class BookmarkComponent extends Component {
  get isBookmarked() {
    return 0 <= bookmark.getIndex(this.props.post)
  }

  async onBookmark() {
    console.log('on bookmark', { post: this.props.post })
    if (bookmark.has(this.props.post)) {
      bookmark.del(this.props.post)
    } else {
      bookmark.add(this.props.post)
    }
  }
  render() {
    return (
      <div className="uk-flex uk-flex-middle">
        <a
          className="post-body uk-link-text uk-text-truncate uk-text-normal"
          href={this.props.post.url}
          style={{ flexGrow: 1 }}>
          {this.props.post.subject}
        </a>
        <a
          className={`bookmark-icon ${bookmark.has(this.props.post) ? 'bookmarked' : 'not-bookmarked'} uk-icon-button uk-text-light uk-text-center uk-text-middle`}
          uk-icon="bookmark"
          style={{ flexShrink: 0, width: '2em', height: '2em' }}
          onClick={() => this.onBookmark()} />
        <span
          className={`badge-${PublisherStr[this.props.post.publisher]} uk-badge uk-text-nowrap uk-text-small uk-text-light`}
          style={{ marginLeft: '0.2em', flexShrink: 0 }} >
          {PublisherDisplayStr[this.props.post.publisher]}
        </span>
      </div>
    )
  }
}
