import { postStore, optionStore } from './stores'
import { fetchApi } from './fetchApi'
import config from './config'
import { Post } from '../../common/define'
import { getIgnoresStr } from './option'

export async function update() {
  console.log('post.update')

  postStore.isLoading = true
  postStore.clear()
  await load()
  postStore.isLoading = false
}

export async function getPosts() {
  console.log('post.getPosts')
  
  return postStore.posts
}

async function load() {
  console.log('post.load')
  
  const url = `${config.apiHost}/posts?rankingType=${optionStore.rankingType}&ignores=${getIgnoresStr()}`
  const postDatas = await fetchApi(url)
  if (postDatas == undefined) {
    return
  }

  var posts = postDatas.map(
    postData => new Post(postData))

  posts.sort((a, b) => b.hit - a.hit)
  posts.forEach(post => postStore.add(post))
}
