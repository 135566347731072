const moment = require('moment')

module.exports.POSTS_PER_PAGE = 100

module.exports.Post = class {
  constructor(props) {
    this.id = Number(props.id)
    this.subject = String(props.subject)
    this.url = String(props.url)
    this.hit = props.hit != undefined ? Number(props.hit) : -1
    this.publisher = Number(props.publisher)
    this.update_time = props.update_time != undefined ? moment(props.update_time) : moment.invalid
    this.post_time = props.post_time != undefined ? moment(props.post_time) : moment.invalid
  }

  printStr() {
    return `${this.publisher}, ${this.hit}, ${this.subject}, ${this.url}`
  }

  isHumorUniv() {
    return this.publisher == Publisher.HUMORUNIV
  }

  getHttpUrl() {
    return this.url.replace('https', 'http')
  }
}

const Publisher = {
  RULIWEB: 1,
  HUMORUNIV: 2,
  TODAYHUMOR: 3,
  BOBAEDREAM: 4,
  ETORREND: 5,
  PPOMPPU: 6,
  THEQOO: 7,
  CLIEN: 8,
  SLRCLUB: 9,
  DDANZI: 10,
  BLIND: 11,
}

module.exports.Publisher = Publisher

const PublisherStr = {
  1: "RULIWEB",
  2: "HUMORUNIV",
  3: "TODAYHUMOR",
  4: "BOBAEDREAM",
  5: "ETORREND",
  6: "PPOMPPU",
  7: "THEQOO",
  8: "CLIEN",
  9: "SLRCLUB",
  10: "DDANZI",
  11: "BLIND",
}
module.exports.PublisherStr = PublisherStr

module.exports.PublisherDisplayStr = {
  1: "루리",
  2: "웃대",
  3: "오유",
  4: "보배",
  5: "토렌",
  6: "뽐뿌",
  7: "더쿠",
  8: "클량",
  9: "SLR",
  10: "딴지",
  11: "블라",
}

module.exports.RequestError = class RequestError extends Error {
  constructor(err, url, ip) {
    super(`request failed : ${err}, ${url}, ${ip}`)
    this.name = 'RequestError'
  }
}

module.exports.fetchHeaders = {
  'User-Agent': 'Mozilla/5.0 (Windows NT 6.1; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/41.0.2272.101 Safari/537.36',
  'Content-Type': 'text/html',
  'Accept-Language': 'ko-kr',
}

module.exports.RankingType = {
  H3: 0,
  H6: 1,
  H12: 2,
  D: 3,
  W: 4,
  M: 5
}

module.exports.RankingTypeDisplayStr = {
  0: '3시간',
  1: '6시간',
  2: '12시간',
  3: '하루',
  4: '1주일',
  5: '1달',
}

module.exports.RankingTypeToHours = {
  0: 3,
  1: 6,
  2: 12,
  3: 24,
  4: 24 * 7,
  5: 24 * 7 * 30,
}
