import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { HeaderComponent } from './components/header'
import { PostsComponent } from './components/post'
import { NoticesComponent } from './components/notice'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { BookmarksComponent } from './components/bookmark'
import { GoogleAdsenseDisplayLeftComponent, GoogleAdsenseDisplayRightComponent, KakaoAdfitComponent } from './components/ad'
// import { NoticeModalComponent } from './components/notice-modal'
// import { BookmarkModalComponent } from './components/bookmark-modal'

@inject('postStore')
@observer
class App extends Component {
  constructor(props) {
    super(props)
    console.log('app init :', props.postStore)
  }

  render() {
    return (
      <div className="App">
        <HeaderComponent />
        <BrowserRouter>
          <Switch>
            <Route exact={true} path="/notice" component={NoticesComponent} />
            <Route exact={true} path="/post" component={PostsComponent} />
            <Route exact={true} path="/bookmark" component={BookmarksComponent} />
            {/* Not Found */}
            <Route component={() => <Redirect to="/post" />} />
          </Switch>
        </BrowserRouter>
        {/* <NoticeModalComponent />
        <BookmarkModalComponent /> */}
        {/* <GoogleAdsenseDisplayLeftComponent /> */}
        {/* <GoogleAdsenseDisplayRightComponent /> */}
        <KakaoAdfitComponent />
      </div>
    );
  }
}

export default App;