import { observable, action } from 'mobx'

const compareHit = (a, b) => {
  return b.hit - a.hit
}

export class PostStore {
  @observable posts = []
  @observable isLoading = false

  @action.bound
  clear() {
    this.posts.length = 0
  }

  @action.bound
  add(post) {
    //console.log(`post added : ${post.subject}`)
    this.posts.push(post)
  }

  sorted() {
    var ps = this.posts.slice()
    ps.sort(compareHit)
    return ps
  }
}
