import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'mobx-react'

import App from './app'
import { postStore, optionStore } from './stores'

ReactDOM.render(
  <Provider
    postStore = { postStore }
    optionStore = { optionStore }
  ><App /></Provider>, document.getElementById("root")
)
