import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { PublisherDisplayStr, PublisherStr } from '../../../common/define'
import { SubHeaderComponent } from './sub-header'
// import { savedPostStore } from '../stores'
import * as bookmark from '../bookmark'
import {
  GoogleAdsenseInFeedComponent, GoogleAdsenseDisplayTopComponent,
  GoogleAdsenseDisplayBottomComponent,
} from './ad'

export const isMobile = _ => {
  const uAgent = navigator.userAgent.toLowerCase()
  const mobilePhones = new Array('iphone', 'ipod', 'ipad', 'android', 'blackberry', 'windows ce', 'nokia', 'webos', 'opera mini', 'sonyericsson', 'opera mobi', 'iemobile')
  return mobilePhones.some(mobile => 0 <= uAgent.search(mobile))
}

@inject('postStore')
@observer
export class PostsComponent extends Component {
  constructor(props) {
    super(props)
    console.log('PostComponents.init() :', props.postStore)
  }

  render() {
    return (
      <div id="posts">
        <SubHeaderComponent isPost={true} />
        {/* <GoogleAdsenseDisplayTopComponent /> */}
        <ul className="uk-list uk-list-divider uk-text-middle">
          {
            0 < this.props.postStore.posts.length &&
            this.props.postStore.posts.slice(0, 50).map((post, i) =>
              <li data-post-id={post.id} className="post" key={post.url}>
                <PostComponent post={post} postIndex={i} />
              </li>
            )
          }
          {
            0 < this.props.postStore.posts.length &&
            <GoogleAdsenseInFeedComponent />
          }
          {
            50 < this.props.postStore.posts.length &&
            this.props.postStore.posts.slice(50).map((post, i) =>
              <li data-post-id={post.id} className="post" key={post.url}>
                <PostComponent post={post} postIndex={50 + i} />
              </li>
            )
          }
        </ul>
        {
          !this.props.postStore.isLoading &&
          this.props.postStore.posts.length <= 0 &&
          <div className="uk-text-center uk-margin">
            no data
        </div>
        }
        {
          this.props.postStore.isLoading &&
          <div className="uk-text-center uk-margin-top uk-margin-bottom" >
            <div uk-spinner="true"></div>
          </div>
        }
        {
          !this.props.postStore.isLoading &&
          this.props.postStore.posts.length > 0 &&
          <div className="uk-text-center uk-text-large uk-margin">
            &#127881; 축하! 당신 클리어 모든 포스트! &#127881;
          </div>
        }
        <GoogleAdsenseDisplayBottomComponent />
      </div>
    );
  }
}

function hitFormat(n) {
  if (n < 1000) {
    return (n / 1000).toFixed(1) + "K"
  }
  else if (n < 1000000) {
    return (n / 1000).toFixed(0) + "K"
  }
  else {
    return (n / 1000000).toFixed(1) + "M"
  }
}

function dateFormat(d) {
  const now = moment()
  const duration = moment.duration(now.diff(d))

  if (duration.asHours() > 48) {
    return duration.asDays().toFixed(1) + "D"
  }
  else if (duration.asHours() >= 1) {
    return duration.asHours().toFixed(0) + "H"
  }
  else {
    return duration.asMinutes().toFixed(0) + "m"
  }
}

function infoFormat(hit, date) {
  return hitFormat(hit) + "\n" + dateFormat(date)
}

@observer
export class PostComponent extends Component {
  get isBookmarked() {
    return 0 <= bookmark.getIndex(this.props.post)
  }

  async onBookmark() {
    console.log('on bookmark', { post: this.props.post })
    if (bookmark.has(this.props.post)) {
      bookmark.del(this.props.post)
    } else {
      bookmark.add(this.props.post)
    }
  }
  render() {
    return (
      <div className="uk-flex uk-flex-middle">
        <span
          className="post-index uk-text-center uk-text-normal"
          style={{ flexShrink: 0, position: 'relative' }}>
          <div style={{ fontSize: '1em' }}>{this.props.postIndex + 1}</div>
          <div style={{ position: 'absolute', top: '2em', width: '100%', fontSize: '0.6em' }}>{hitFormat(this.props.post.hit)}</div>
        </span>
        {/* <span className="post-divider uk-margin-small-right" /> */}
        <a
          className="post-body uk-link-text uk-text-truncate uk-text-normal"
          href={!isMobile() && this.props.post.isHumorUniv() ? this.props.post.getHttpUrl() : this.props.post.url}
          style={{ flexGrow: 1 }}>
          {this.props.post.subject}
        </a>
        <a
          className={`bookmark-icon ${bookmark.has(this.props.post) ? 'bookmarked' : 'not-bookmarked'} uk-icon-button uk-text-light uk-text-center uk-text-middle`}
          uk-icon="bookmark"
          style={{ flexShrink: 0, width: '2em', height: '2em' }}
          onClick={() => this.onBookmark()} />
        {/* <div
          className={`post-info uk-text-small uk-text-light uk-text-center uk-text-middle`}
          style={{ width: '3em', flexShrink: 0 }}>
          {infoFormat(this.props.post.hit, this.props.post.post_time).split('\n').map((t, key) => <div key={key}>{t}</div>)}
        </div> */}
        <span
          className={`badge-${PublisherStr[this.props.post.publisher]} uk-badge uk-text-nowrap uk-text-small uk-text-light`}
          style={{ marginLeft: '0.2em', flexShrink: 0 }} >
          {PublisherDisplayStr[this.props.post.publisher]}
        </span>
      </div>
    )
  }
}
