import React, { Component } from 'react'

export class GoogleAdsenseDisplayLeftComponent extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return <div id="google-adsense-display-left" >
      <ins className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-8837426857286320"
        data-ad-slot="4797342946"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div >
  }
}

export class GoogleAdsenseDisplayRightComponent extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return <div id="google-adsense-display-right" >
      <ins className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-8837426857286320"
        data-ad-slot="5890932761"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div >
  }
}

export class GoogleAdsenseDisplayTopComponent extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return <div id="google-adsense-display-top"
    // style={{ backgroundColor: 'lightgray' }}
    >
      <ins className="adsbygoogle"
        style={{ display: 'block', textAlign: 'center' }}
        data-ad-client="ca-pub-8837426857286320"
        data-ad-slot="8158342767"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div >
  }
}

export class GoogleAdsenseInFeedComponent extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return <div id="google-adsense-in-feed"
    // style={{ backgroundColor: 'lightgray' }}
    >
      <ins className="adsbygoogle"
        style={{ display: 'block', textAlign: 'center' }}
        data-ad-layout="in-article"
        data-ad-format="fluid"
        data-ad-client="ca-pub-8837426857286320"
        data-ad-slot="7590548318" ></ins>
    </div>
  }
}

export class GoogleAdsenseDisplayBottomComponent extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return <div id="google-adsense-display-bottom"
    // style={{ backgroundColor: 'lightgray' }}
    >
      <ins className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-8837426857286320"
        data-ad-slot="8594484704"
        data-ad-format="auto"
        data-full-width-responsive="true"></ins>
    </div>
  }
}

export class KakaoAdfitComponent extends Component {
  render() {
    return <div id="kakaoadfit"
    // style={{ backgroundColor: 'lightgray' }}
    >
      <ins className="kakao_ad_area" style={{ display: 'none' }}
        data-ad-unit="DAN-YSj8cAfdYg07ajxO"
        data-ad-width="728"
        data-ad-height="90"></ins>
      <script type="text/javascript" src="//t1.daumcdn.net/kas/static/ba.min.js" async></script>
    </div>
  }
}
