import { observable, action } from 'mobx'
import { Publisher, RankingType } from '../../../common/define'

export class OptionStore {
  @observable ignores = new Set()
  @observable rankingType = RankingType.DAYILY

  @action.bound
  showPublisher(publisher) {
    return this.ignores.delete(publisher)
  }

  @action.bound
  hidePublisher(publisher) {
    for (const key in Publisher) {
      if (publisher == Publisher[key]) {
        this.ignores.add(publisher)
        break
      }
    }
  }

  @action.bound
  setRankingType(value) {
    this.rankingType = value
  }
}
