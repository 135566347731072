import React, { Component } from 'react'
import { observer } from 'mobx-react'
import { noticeStore } from '../stores'
import showdown from 'showdown'
const converter = new showdown.Converter()

@observer
export class NoticeModalComponent extends Component {
  constructor(props) {
    super(props)
  }

  static renderButton() {
    // return <div uk-toggle="target: #notice-modal">
    //   <span>공지 사항</span>
    //   <span uk-icon="icon: info" className="uk-margin-small-left" />
    // </div>
    // return <li className="uk-nav-header " uk-toggle="target: #notice-modal">공지 보기</li>
    return <li uk-toggle="target: #notice-modal"><span uk-icon="icon: info"/> 공지 보기</li>
    // return <button
    //   className="uk-button uk-button-small uk-button-default force"
    //   uk-toggle="target: #notice-modal" type="button">
    //   공지 보기
    //     <span uk-icon="icon: info" className="uk-margin-small-left" />
    // </button>
  }

  render() {
    return <div id="notice-modal" uk-modal="uk-modal" >
      <div className="uk-modal-dialog uk-height-1-1" uk-overflow-auto="uk-overflow-auto">
        <button className="uk-modal-close-default" type="button" uk-close="uk-close"></button>

        <div className="uk-modal-header">
          <h2 className="uk-modal-title">공지</h2>
        </div>

        <div className="uk-modal-body" >
          <ul className="uk-list uk-list-divider uk-text-middle">
            {
              noticeStore.notices.map((notice, i) =>
                <li data-notice-id={i} className="notice" key={i}>
                  <NoticeComponent noticeIndex={i} notice={notice} />
                </li>
              )
            }
          </ul>
        </div>
      </div>
    </div>
  }
}

class NoticeComponent extends Component {
  render() {
    return (
      <div>
        <div
          className="uk-flex"
          uk-toggle={`target:#notice${this.props.noticeIndex}content`}>
          <div className="uk-link-text uk-text-truncate uk-text-normal uk-width-1-1" >
            {this.props.notice[0]}
          </div>
          <div className="post-info uk-text-light uk-text-center uk-text-middle uk-margin-auto">
            {this.props.notice[2].format('MM/DD')}
          </div>
        </div>
        <div
          hidden
          className="uk-padding-small"
          style={{ backgroundColor: 'beige' }}
          id={`notice${this.props.noticeIndex}content`}
          uk-toggle={`target:#notice${this.props.noticeIndex}content`}
          dangerouslySetInnerHTML={{ __html: converter.makeHtml(this.props.notice[1]) }}>
        </div>
      </div>
    )
  }
}