import React, { Component } from 'react'
import { observer } from 'mobx-react'
import * as bookmark from '../bookmark'
import { PublisherStr, PublisherDisplayStr } from '../../../common/define'
// import { PostComponent } from './post'

@observer
export class BookmarkModalComponent extends Component {
  constructor(props) {
    super(props)
  }

  static renderButton() {
    // return <button
    //   className="uk-button uk-button-small uk-button-default force"
    //   uk-toggle="target: #bookmark-modal" type="button">
    //   북마크 보기
    //     <span uk-icon="icon: info" className="uk-margin-small-left" />
    // </button>
    return <li uk-toggle="target: #bookmark-modal"><span uk-icon="icon: bookmark"/> 다시 보기</li>
  }

  render() {
    return <div id="bookmark-modal" uk-modal="uk-modal" >
      <div className="uk-modal-dialog uk-height-1-1" uk-overflow-auto="uk-overflow-auto">
        <button className="uk-modal-close-default" type="button" uk-close="uk-close"></button>

        <div className="uk-modal-header">
          <h2 className="uk-modal-title">다시 보기</h2>
        </div>

        <div className="uk-modal-body" >
          <ul className="uk-list uk-list-divider uk-text-middle">
            {
              bookmark.reverse().map((post, i) =>
                <li data-post-id={i} className="post" key={i}>
                  <BookmarkPostComponent post={post} postIndex={i} />
                </li>
              )
            }
          </ul>
        </div>
      </div>
    </div>
  }
}


@observer
export class BookmarkPostComponent extends Component {
  get isBookmarked() {
    return 0 <= bookmark.getIndex(this.props.post)
  }

  async onBookmark() {
    console.log('on bookmark', { post: this.props.post })
    if (bookmark.has(this.props.post)) {
      bookmark.del(this.props.post)
    } else {
      bookmark.add(this.props.post)
    }
  }
  render() {
    return (
      <div className="uk-flex uk-flex-middle">
        <a className="post-body uk-link-text uk-text-truncate uk-text-normal" href={this.props.post.url} style={{ flexGrow: 1 }}>
          {this.props.post.subject}
        </a>
        <a className={`bookmark-icon ${bookmark.has(this.props.post) ? 'bookmarked' : 'not-bookmarked'} uk-icon-button uk-text-light uk-text-center uk-text-middle`} uk-icon="bookmark" style={{ flexShrink: 0 }} onClick={() => this.onBookmark()}></a>
        <span className={`badge-${PublisherStr[this.props.post.publisher]} 
            uk-badge uk-text-nowrap uk-text-small uk-text-light`} style={{ marginLeft: '0.2em', flexShrink: 0 }} >
          {PublisherDisplayStr[this.props.post.publisher]}
        </span>
      </div>
    )
  }
}

