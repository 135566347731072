import 'babel-polyfill'

let globalAsyncJobNonce;

export async function fetchApi(url) {
  console.log('fetchApi ', url)

  // for exclusive async job
  const localNonce = globalAsyncJobNonce = new Object();

  const res = await fetch(url)

  if (localNonce !== globalAsyncJobNonce) {
    console.log('fetch() interruped')
    return undefined
  }

  const { succeed, posts } = await res.json()

  if (localNonce !== globalAsyncJobNonce) {
    console.log('fetch() interruped')
    return undefined
  }

  console.log('fetch() result :', succeed, { posts })
  return posts
}
