import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import { Publisher, PublisherDisplayStr, RankingType, RankingTypeDisplayStr } from '../../../common/define'
import { getPublisherVisible, togglePublisherVisible, getRankingType, setRankingType } from '../option'
import { update } from '../post'
import { NoticeModalComponent } from './notice-modal'
import { BookmarkModalComponent } from './bookmark-modal'
// import { BuymeacoffeeComponent } from './buymeacoffee'

@observer
export class SideBarComponent extends Component {
  async handleToggleVisible(publisher) {
    console.log('handle visible publisher', publisher)
    togglePublisherVisible(publisher)
    console.log(` ${publisher} visible changed -> ${getPublisherVisible(publisher)}`)
    await update()
  }

  handleRankingType(rankingType) {
    console.log('handle rankingType', rankingType)
    setRankingType(rankingType)
    UIkit.offcanvas(document.querySelector('#sidebar')).hide()
  }

  render() {
    return (
      <div id="sidebar" uk-offcanvas="overlay: true">
        <div className="uk-offcanvas-bar uk-flex uk-flex-column">
          <h3 className="uk-logo uk-text-center">Fun iOO</h3>
          <div className="uk-flex uk-flex-column">
            <div uk-grid="true" className="uk-grid uk-grid-collapse" style={{ marginLeft: 0 }}>
              {
                Object.entries(Publisher).map(p =>
                  <div className="uk-width-1-4 uk-text-center" key={p} style={{ paddingLeft: 0, padding: '0.3em' }}>
                    <span className={`uk-badge ${p[0]} ${getPublisherVisible(p[1]) ? `badge-${p[0]}` : "badge-diabled"}`}
                      style={{ cursor: 'pointer', width: '100%' }}
                      onClick={() => this.handleToggleVisible(p[1])}
                    >
                      {PublisherDisplayStr[p[1]]}
                    </span>
                  </div>
                )
              }
            </div>
            <hr></hr>
            <div className="uk-flex uk-flex-row uk-flex-wrap">
              {
                Object.entries(RankingType).map((k, v) =>
                  <button key={k}
                    className={"uk-button uk-button-small force " + (getRankingType() == v ? "uk-button-default" : "uk-button-secondary")}
                    style={{ width: '50%' }}
                    onClick={() => this.handleRankingType(v)} >
                    {RankingTypeDisplayStr[v]}
                  </button>
                )
              }
            </div>
          </div>
          <div className="uk-flex uk-flex-column-reverse" style={{ flexGrow: 1 }}>
            <div className="uk-flex uk-flex-row uk-flex-center uk-margin-bottom">
              <a href="https://m.me/102364628213260" target="_blank">
                <img src="/static/images/facebook-icon-circle-logo.png" alt="페이스북 문의하기"
                  style={{ height: '50px' }}
                />
              </a>
              <a href="http://pf.kakao.com/_ITpYxb/chat" className="uk-margin-left" target="_blank">
                <img src="/static/images/kakaotalk-icon-circle.png" alt="카카오톡 문의하기"
                  style={{ height: '50px' }}
                />
              </a>
              <a href="mailto:fun100@suhmplus.com" className="uk-margin-left" target="_blank">
                <img src="/static/images/orange-transparent-email-icon-red-email-icons-11563007385mpkiku3cqg(2).png" alt="이메일 문의하기"
                  style={{ height: '50px' }}
                />
              </a>
            </div>
            <hr></hr>
          </div>
        </div>
      </div>
    );
  }
}
