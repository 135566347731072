import { bookmarkStore } from './stores'

export function init() {
  const posts = window.localStorage.getItem('bookmarks')
  if (posts) {
    JSON.parse(posts).forEach(bookmarkStore.add)
  }
}

export function add(post) {
  bookmarkStore.add(post)
  store()
}

export function del(post) {
  const index = getIndex(post)
  if (0 <= index) {
    bookmarkStore.del(index)
    store()
  }
}

export function getIndex(post) {
  return bookmarkStore.posts.findIndex(({ publisher, id }) => publisher === post.publisher && id === post.id)
}

export function has(post) {
  return 0 <= getIndex(post)
}

export function reverse() {
  console.log('all:', bookmarkStore.posts)
  return bookmarkStore.posts.reverse()
}

export function count() {
  return bookmarkStore.posts.length
}

function store() {
  window.localStorage.setItem('bookmarks', JSON.stringify(bookmarkStore.posts))
}

init()